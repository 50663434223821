<template>
  <div class="three-scene-2" ref="three-scene-2" onselectstart="return false;">
    <!-- <div @pointerdown="(e) => e.stopPropagation()" class="btn">
      <button
        v-for="(_, name) in specialEffects"
        @click="
          () => {
            showSpecialEffects(name);
          }
        "
      >
        {{ name }}
      </button>

      <button>
        <router-link to="scene-1">跳转主场景</router-link>
      </button>
      <button>
        <router-link to="scene-3">跳转整县推进</router-link>
      </button>

      <button @click="restPosition">重置视角</button>

      <button @click="closeAllSpecialEffects">清除所有光效</button>
    </div> -->
  </div>
</template>

<script>
// chang 事件 实例
let sceneChange = null;
// 场景
let scene = null;
import Change from "./Change";
import { RunScene } from "run-scene-v2";
import bus from "./../utils/bus";
import { specialEffects } from "./const";

export default {
  data() {
    return {
      specialEffects: specialEffects,
    };
  },
  activated(){
    this.$refs['three-scene-2'].style.display="block"

  },
  deactivated(){
    this.$refs['three-scene-2'].style.display="none"
  },
  mounted() {
    document.getElementById("app").appendChild(this.$refs['three-scene-2'])
     // this.$refs['three-scene-1'].parentNode.removeChild(this.$refs['three-scene-1'])
    // 加载场景
    this.loadScene();
    // 打印点击的模型接口
    bus.on("logClickModel-2", this.logClickModel);
  },
  methods: {
    // 加载场景
    loadScene() {
      scene = new RunScene({
        msg: {
          // show: true,
        },
        // showFps: true,
        coverSameId: true,
        instanceClone: false,
        render3: true,
        render2: true,
        renderConfig: {
          // 是否允许设置模型位置后自动渲染最新效果
          matrixAutoUpdate: true,
          scriptFrame: 60,
        },
      })
        .load({
          // path: "http://192.168.3.8:8080/file?path=project/linkpoint/&key=202211301336524395151001202236",
          path: process.env.VUE_APP_BASE_ASSETS+"assets/scene2.glb",
          // path: "https://test2-1303915342.cos.ap-shanghai.myqcloud.com/goodwe/scene2.glb",
          dom: this.$refs["three-scene-2"],
        })

      // 实例化场景
      sceneChange = new Change(scene, this.onDone);
      window.sceneChange2=scene
    },

    // 加载完回调
    onDone() {
      this.$emit("onDone");
      console.log("场景加载完毕~");
    },
    // 清除 所有光效
    closeAllSpecialEffects() {
      bus.emit("closeAllSpecialEffects");
    },

    // 重置 视角
    restPosition() {
      sceneChange.cameraPoint.reset();
    },

    // 打印点击到的模型
    logClickModel(model) {
      console.log("点击的模型为:", model.name);
    },

    // 显示特效
    showSpecialEffects(name) {
      bus.emit("isShowSpecialEffects", name);
    },
  },
  // 场景自带销毁
  unmounted() {
    sceneChange && sceneChange.dispose();
    sceneChange = null;
  },
};

// 导出场景 资源
export { sceneChange, scene };
</script>

<style lang="less" scoped>
// 场景
.three-scene-2 {
  width: 100%;
  height: 100%;
  position: fixed;
}

.three-scene-2 .btn {
  position: absolute;
  z-index: 2;
}

.three-scene-2 .show {
  opacity: 1 !important;
}

.three-scene-2 .none {
  opacity: 0 !important;
}

.three-scene-2 .block {
  display: block !important;
}
</style>
