// 视角点位
const viewMap = {
    厂房办公楼宿舍建筑: {
        cx: -59.68872157746631, cy: 38.36867711506414, cz: -29.855890853949695, tx: 10.076387238596622, ty: -16.665565, tz: 9.697947708211467
    },
    充电桩和充电车子: {
        cx: -28.095113411136012, cy: 30.95558641347767, cz: 2.067993203819576, tx: 11.700298446767817, ty: -16.665565, tz: 37.57288970968099
    },
    光伏: {
        cx: -59.68872157746631, cy: 38.36867711506414, cz: -29.855890853949695, tx: 10.076387238596622, ty: -16.665565, tz: 9.697947708211467
    },
    储能集装箱: {
        cx: -22.24704387099699, cy: 20.87452724912471, cz: -44.39623002319915, tx: 16.430257744204887, ty: -16.665565, tz: -15.196370917593747
    },
    虚拟电厂: {
        cx: -54.55720735387734, cy: 38.462376307106894, cz: 14.91394637156884, tx: -10.52961644016191, ty: -16.665565, tz: 50.95189029104133
    },
}

// 特效
const specialEffects = {
    '厂房办公楼宿舍建筑': null,
    '储能集装箱': null,
    '光伏': null,
    '虚拟电厂': null,
    '充电桩和充电车子': null,
    '逆变器特效': null
}
export { specialEffects, viewMap }