// const console = {
//   log: () => { }
// }

import { Utils } from "run-scene-v2";
import bus from "./../utils/bus";
import * as THREE from "three";
import { specialEffects, viewMap } from "./const";

// 声明变量
let camera, scene, controls, renderer2, renderer, dom, t, p, runScene, Bus;
// 工具
const { getMacro } = Utils;

// 拿资源
const setAssets = (assets) => {
  camera = assets.camera;
  scene = assets.scene;
  controls = assets.controls;
  renderer = assets.renderer;
  dom = assets.engineDom;
  t = assets.t;
};

//  页面接口总出口
function Change(runScene, onDone) {
  setAssets({ ...runScene.assetsEx.get(), t: this, runScene });

  t.runScene = runScene;

  t.onDone = onDone;

  // 场景事件
  this.events = null;
  // 工具
  this.tool = null;

  this.specialEffects = null;

  // 最后一帧加载回调
  t.runScene.on("loaded", async () => {
    this.events = new Events();

    this.tool = new Tool();

    this.specialEffects = new SpecialEffects();

    this.specialEffects.init();

    this.cameraPoint = new CameraPoint();

    this.cameraPoint.init();

    this.anima = new Anima();

    this.anima.init();

    controls.mouseButtons = {
      PAN: THREE.MOUSE.LEFT,
      ZOOM: THREE.MOUSE.MIDDLE,
      ORBIT: THREE.MOUSE.RIGHT,
    };

    // 基本配置
    (() => {
      controls.maxPolarAngle = Math.PI / 2 - 0.2;

      controls.maxDistance = 180;

      controls.minDistance = 50;

      controls.screenSpacePanning = false;
      // 入场动画
      t.cameraPoint.reset();

      t.runScene.script.playAll();

      t.runScene.assetsEx.controls.autoRotate = false


      const resizeCanvas = function (x) {

        let map = t.runScene.assetsEx.engineDom.getBoundingClientRect();
        t.runScene.setSize(map.width, map.height);


      };

      // resizeCanvas()
      bus.emit("resize", resizeCanvas);
      bus.on("changeScene", (x) => {
        setTimeout(() => {
          resizeCanvas(x);
        }, 0);
      });

      // 加载回调
      t.onDone();
    })();
  });

  // 销毁
  this.dispose = () => runScene.dispose();
}


class Anima {

  init() {
    this.runAnima()
  }

  runAnima() {

    //播放动画
    t.runScene.anima.play(t.runScene.modelEx.getModel('无人机'), "Take 001", {
      loop: true
    })

    t.runScene.anima.play(t.runScene.modelEx.getModel('无人机2'), "Take 001", {
      loop: true
    })

    t.runScene.anima.play(t.runScene.modelEx.getModel('dianchi'), "Take 001", {
      loop: true
    })

    t.runScene.anima.play(
      t.runScene.modelEx.getModel("cloud"),
      "Take 001",
      {
        loop: true,
      }
    );

  }
}


// 相机 点位
class CameraPoint {
  init() {
    bus.on("scene-2-cameraAnima", this.setView.bind(this));
    bus.on("scene-2-reset", this.reset.bind(this));
  }
  setView(area, time = 2) {
    if (!viewMap[area]) return;
    t.tool.cameraAnima(viewMap[area], time);
  }
  reset() {
    t.events.cameraAnima(
      {
        cx: -72.65908709208024,
        cy: 75.4092539984953,
        cz: -33.50127550256105,
        tx: 6.442922126427786,
        ty: -16.665565,
        tz: -0.8551559541474187,
      },
      2
    );
  }
}

// 特效
class SpecialEffects {
  init() {
    this.getModel();
    // 注册 特效bus
    bus.on("isShowSpecialEffects", this.isShowSpecialEffects.bind(this));

    // 清除所有光效
    bus.on("closeAllSpecialEffects", this.closeAllSpecialEffects.bind(this));

    console.log(specialEffects, 'specialEffects');
  }

  // 获取 模型
  getModel() {
    Object.keys(specialEffects).map((name) => {
      specialEffects[name] = t.runScene.modelEx.getModel(name);
    });
  }

  // 显示特效
  isShowSpecialEffects(name) {
    if (!specialEffects[name]) return;
    Object.keys(specialEffects).map((specialEffectsName) => {
      const model = specialEffects[specialEffectsName]
      if (specialEffectsName === name) {
        model.visible = true;
      } else {
        if (!model.visible) return
        model.visible = false;
      }
    })

    this.synchronicity();
  }

  closeAllSpecialEffects() {
    Object.keys(specialEffects).map((specialEffectsName) => {
      const model = specialEffects[specialEffectsName]
      model.visible = false;
    })
    // 同步效果
    this.synchronicity();
  }

  // 同步效果
  synchronicity() {
    specialEffects['逆变器特效'].visible = specialEffects['储能集装箱'].visible;
  }
}

// 工具方法 ( 可封的公共方法 )
class Tool {
  // 相机 动画
  cameraAnima(position, time = 1, fn) {
    t.events.closeAnimaAtStart.cameraAnima = Utils.anima(
      {
        cx: t.runScene.assetsEx.camera.position.x,
        cy: t.runScene.assetsEx.camera.position.y,
        cz: t.runScene.assetsEx.camera.position.z,
        tx: t.runScene.assetsEx.controls.target.x,
        ty: t.runScene.assetsEx.controls.target.y,
        tz: t.runScene.assetsEx.controls.target.z,
      },
      {
        ...position,
      },
      time,
      (data) => {
        t.runScene.assetsEx.camera.position.set(data.cx, data.cy, data.cz);
        t.runScene.assetsEx.controls.target.set(data.tx, data.ty, data.tz);
        t.runScene.assetsEx.camera.updateProjectionMatrix();
        t.runScene.assetsEx.controls.update();
      },
      () => {
        fn && fn();
      }
    );
  }
  // 模型 透明度 渐变动画
  showAnima(info) {
    const { model, isShow, time, cb, opacity } = info;
    const models = [];
    model.traverse((m) => {
      if (m.type === "Group") return;
      if (m.type === "Object3D") return;
      m.material.transparent = true;
      isShow ? (m.material.opacity = 0) : null;
      models.push(m);
    });
    if (isShow) model.visible = isShow;
    Utils.anima(
      { opc: isShow ? 0 : opacity || 1 },
      { opc: isShow ? opacity || 1 : 0 },
      time,
      (data) => {
        models.map((m) => (m.material.opacity = data.opc));
      },
      () => {
        if (!isShow) model.visible = isShow;
        cb && cb();
      }
    );
  }
}

// 基本事件
class Events {
  downPosition = { x: 0, y: 0 };

  closeAnimaAtStart = { enterAnima: "" };
  scopeTimer = null;
  scopeAble = true;

  constructor() {
    t.runScene.assetsEx.controls.addEventListener("start", this.controlStart);
    t.runScene.cb.model.setSelect.add(
      "trigger-click",
      this.triggerClick.bind(this)
    );
    t.runScene.cb.events.pointer.down.add("trigger", (e) =>
      t.runScene.modelEx.selectNull()
    );
    document.addEventListener("click", (e) => { });
    t.runScene.cb.render.add("controScope", () => {
      let distance = camera.position.distanceTo({ x: 0, y: 0, z: 0 });
      if (this.scopeAble) {
        if (distance >= 140) {
          controls.enablePan = false;
          this.scopeAble = false;
          this.scopeTimer = setTimeout(() => {
            t.tool.cameraAnima(
              {
                cx: -72.65908709208024,
                cy: 75.4092539984953,
                cz: -33.50127550256105,
                tx: 6.442922126427786,
                ty: -16.665565,
                tz: -0.8551559541474187,
              },
              1.5,
              () => {
                controls.enablePan = true;
                this.scopeAble = true;
              }
            );
            clearTimeout(this.scopeTimer);
          }, 1500);
        } else {
          controls.enablePan = true;
          this.scopeAble = true;
        }
      }
    });
  }

  triggerClick = (model) => {
    if (!model) return;
    const log = `cx:${camera.position.x}, cy:${camera.position.y}, cz:${camera.position.z}, tx:${controls.target.x}, ty:${controls.target.y}, tz:${controls.target.z} `;
    console.log("点位:", log);
    bus.emit("logClickModel-2", model);
  };

  cameraAnima(position, time = 1) {
    this.closeAnimaAtStart.cameraAnimaFn = Utils.anima(
      {
        cx: t.runScene.assetsEx.camera.position.x,
        cy: t.runScene.assetsEx.camera.position.y,
        cz: t.runScene.assetsEx.camera.position.z,
        tx: t.runScene.assetsEx.controls.target.x,
        ty: t.runScene.assetsEx.controls.target.y,
        tz: t.runScene.assetsEx.controls.target.z,
      },
      {
        ...position,
      },
      time,
      (data) => {
        t.runScene.assetsEx.camera.position.set(data.cx, data.cy, data.cz);
        t.runScene.assetsEx.controls.target.set(data.tx, data.ty, data.tz);
        t.runScene.assetsEx.camera.updateProjectionMatrix();
        t.runScene.assetsEx.controls.update();
      }
    );
  }
  // 清除动画
  controlStart = () => {
    this.closeAnmia();
  };

  // 暂停动画 并清空内容 item就是那个动画
  closeAnmia() {
    Object.values(this.closeAnimaAtStart).map((i) => i && i.kill());
  }

  dispose() {
    controls.removeEventListener("start", this.controlStart);
  }
}

export default Change;
