<template>
  <div class="scene1">
    <scene2 @onDone="onDone"></scene2>
    <transition name="fade">
      <div
        class="boxsss"
        v-show="showUIName !== '' && !rightUI.includes(showUIName)"
      >
        <component :is="showUIName"></component>
      </div>
    </transition>

    <transition name="rightFade">
      <div
        class="boxsss-right"
        v-show="showUIName !== '' && rightUI.includes(showUIName)"
      >
        <component :is="showUIName"></component>
      </div>
    </transition>
    <div class="lottie" v-if="loadingAn">
      <lottie-web></lottie-web>
    </div>
    <LargeVideo
      v-if="isShowVideo"
      :videoSrc="video1"
      ref="videoScene2Ref"
    ></LargeVideo>
  </div>
</template>
<script>
import scene2 from "./../components/three-scene-2/index.vue";
import SmartPcs from "./components/smart-pcs.vue";
import TopLight from "./components/top-light.vue";
import CarEle from "./components/car-ele.vue";
import ControlTop from "./components/control-top.vue";
import JieSuan from "./components/jie-suan.vue";
import WePower from "./components/we-power.vue";
import LargeVideo from "./components/large-video.vue";
import bus from "@/components/utils/bus.js";
import mitt from "@/lib/bus"
export default {
  components: {
    scene2,
    TopLight,
    SmartPcs,
    CarEle,
    ControlTop,
    JieSuan,
    WePower,
    LargeVideo,
  },
  data() {
    return {
      isCloseWs: false,
      loadingAn: true,
      video1: "",
      isShowVideo: false,
      socket: "",
      showUIName: null,
      rightUI: ["SmartPcs"],
      devMap: {
        closeVideo: {
          camMap: null,
          route: null,
          UI: "keep",
          video: {
            type: "close",
          },
        },
        playVideo: {
          camMap: null,
          route: null,
          UI: "keep",
          video: {
            type: "play",
          },
        },
        pauseVideo: {
          camMap: null,
          route: null,
          UI: "keep",
          video: {
            type: "pause",
          },
        },
        openWPVideo: {
          camMap: null,
          UI: "keep",
          video: {
            type: "open",
            src: process.env.VUE_APP_BASE_ASSETS+ "assets/video/wepower.mp4",
          },
          route: null,
        },
        toScene1: {
          camMap: null,
          UI: null,
          route: "scene1",
        },
        toMain1: {
          camMap: null,
          UI: null,
          route: "scene1",
        },
        toGuang: {
          camMap: "光伏",
          UI: "topLight",
          route: null,
        },
        toChu: {
          camMap: "储能集装箱",
          UI: "SmartPcs",
          route: null,
        },
        toChong: {
          camMap: "充电桩和充电车子",
          UI: "CarEle",
          route: null,
        },
        toYong: {
          camMap: "厂房办公楼宿舍建筑",
          UI: "ControlTop",
          route: null,
        },
        toShou: {
          camMap: "",
          UI: "JieSuan",
          route: null,
        },
        toPower: {
          camMap: "",
          UI: "WePower",
          route: null,
        },
        returnMain2: {
          camMap: "重置",
          UI: null,
          route: null,
        },
        toScene3: {
          camMap: null,
          UI: null,
          route: "scene3",
        },
      },
    };
  },
  // mounted() {
  //   let _this=this
  //   setTimeout(() => {
  //     mitt.on("sod",(data)=>{
  //     // console.log(_this);
  //     _this.wsMessage({data})
  //   })
  //   }, 1000);
  // },
  methods: {
    onDone() {
      this.loadingAn = false;
      this.$emit("loadend");
      let _this = this
      setTimeout(() => {
        mitt.on("sod", (data) => {
          // console.log(_this);
          _this.wsMessage({ data })
        })
      }, 1000);
    },
    initWebSocket() {
      let wsUrl = process.env.VUE_APP_BASE_WSURL;
      this.socket = new WebSocket(wsUrl);
      this.socket.onmessage = this.wsMessage;
      this.socket.onopen = this.wsOpen;
      this.socket.onclose = this.wsClose;
      this.socket.onerror = this.wsError;
    },
    handleOrder(dev) {
      let { camMap, UI, route, video } = this.devMap[dev];
      if (route) {
        this.$router.push({ name: route });
      }
      if (camMap) {
        let where = camMap;
        if (where == "重置") {
          bus.emit("closeAllSpecialEffects");
        } else {
          bus.emit("isShowSpecialEffects", where);
        }
      } else {
        bus.emit("closeAllSpecialEffects");
      }
      if (UI) {
        if (UI !== "keep") {
          this.showUIName = UI;
        }
      } else {
        this.showUIName = "";
      }
      if (video) {
        if (video.type == "open") {
          this.video1 = video.src;
          this.isShowVideo = true;
        } else if (video.type == "close") {
          this.isShowVideo = false;
        } else if (video.type == "pause") {
          this.$refs.videoScene2Ref.pauseVideo();
        } else if (video.type == "play") {
          this.$refs.videoScene2Ref.playVideo();
        }
      }
    },
    wsMessage({ data }) {
      try {
        let message = JSON.parse(data);
        if (message.scene.includes("scene2")) {
          let { deactive } = message;
          this.handleOrder(deactive);
        }
      } catch (e) {
        console.log("错误捕获", e);
      }
    },
    wsOpen() {
      console.log("打开webso");
    },
    wsClose() {
      console.log("关闭ws");
      if (!this.isCloseWs) {
        this.initWebSocket();
      }
    },
    wsError(e) {
      console.log("错误", e);
    },
  },
  unmounted() {
    // this.isCloseWs = true;
    // this.socket.close();
    mitt.off('sod')
  },
};
</script>
<style lang="less" scoped>
.scene1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.lottie {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
